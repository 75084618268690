.container {
    width: 100%;
    max-width: none !important;
    height: 100%;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-image: radial-gradient(farthest-corner at 50% 100%, #1C7CE0 0%, #150051 100%);
    animation: filter-animation 6s infinite ease-in;
}

.errorText {
    font-size: 5rem !important;
    color: white;
}

.text {
    font-size: 3rem !important;
    color: white;
}
